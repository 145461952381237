import React from "react"
import localization from "../../lang"
import {getReport} from "../../utils/api";
import {NotificationManager} from "../Notification";
import NotFound from "../../pages/404";
import Error from "../../pages/500";
import ReportTierEnum from "../Report/ReportTierEnum";
import PricingPanel from "./PricingPanel";

const CHECKOUT_PERSONAL = process.env.GATSBY_CHECKOUT_PERSONAL;
const CHECKOUT_DEVELOPMENT = process.env.GATSBY_CHECKOUT_DEVELOPMENT;
const CHECKOUT_DEVELOPMENT_UPGRADE = process.env.GATSBY_CHECKOUT_DEVELOPMENT_UPGRADE;

class Pricing extends React.Component {
    state = {
        isLoading: false,
        language: 'en_US',
        reportHash: null,
        reportState: 'unknown',
        reportType: 'regular',
        reportTier: ReportTierEnum.TIER_SUMMARY,
        reportEmail: null,
        questionsTotal: 0,
        questionsAnswered: 0,
        reportFound: true,
        errorEncountered: false,
    }

    componentDidMount = () => {
        // Fetch basic information about the report by provided hash
        const {hash} = this.props
        getReport(hash, {}, () => {
            this.setState({
                isLoading: true
            })
        }).then(response => {
            const {data} = response.data
            this.setState({
                language: data.preferred_language,
                reportState: data.status,
                reportType: data.type,
                questionsTotal: data.questions_total,
                questionsAnswered: data.questions_answered,
                reportHash: hash,
                reportTier: data.code ?? ReportTierEnum.TIER_SUMMARY,
                reportEmail: data.email,
            })
        }).catch(e => {
            const {response} = e

            if (response === undefined) {
                this.setState({
                    errorEncountered: true
                })
                return
            }

            const {errors} = response.data

            if (e.response.status === 404) {
                this.setState({
                    reportFound: false
                })
            }

            for (const err of errors) {
                NotificationManager.error(err.title, 3000)
            }
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {
            language,
            reportFound,
            errorEncountered,
            reportHash,
            reportEmail,
            reportTier,
        } = this.state

        let locale;
        if (localization.hasOwnProperty(language)) {
            locale = localization[language]
        } else {
            locale = localization['en_US']
        }

        if (errorEncountered === true) {
            return <Error/>
        }

        if (reportFound !== true) {
            return <NotFound/>
        }

        return (
            <div className={'flex items-center justify-center'}>
                <div className="container mx-5 my-5">
                    <div className="sm:flex sm:flex-col sm:align-center">
                        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Plans &amp; Pricing</h1>
                    </div>
                    <div
                        className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                            {reportTier === ReportTierEnum.TIER_SUMMARY &&
                                <PricingPanel
                                    hash={reportHash}
                                    email={reportEmail}
                                    locale={locale.pricing.personal}
                                    type={ReportTierEnum.TIER_PERSONAL}
                                    action={CHECKOUT_PERSONAL + '?client_reference_id=' + reportHash + '&prefilled_email=' + reportEmail}
                                />
                            }
                            {reportTier === ReportTierEnum.TIER_SUMMARY &&
                                <PricingPanel
                                    hash={reportHash}
                                    email={reportEmail}
                                    locale={locale.pricing.development}
                                    type={ReportTierEnum.TIER_DEVELOPMENT}
                                    action={CHECKOUT_DEVELOPMENT + '?client_reference_id=' + reportHash + '&prefilled_email=' + reportEmail}
                                />
                            }
                            {reportTier === ReportTierEnum.TIER_PERSONAL &&
                                <PricingPanel
                                    hash={reportHash}
                                    email={reportEmail}
                                    locale={locale.pricing.developmentUpgrade}
                                    type={ReportTierEnum.TIER_DEVELOPMENT_UPGRADE}
                                    action={CHECKOUT_DEVELOPMENT_UPGRADE + '?client_reference_id=' + reportHash + '&prefilled_email=' + reportEmail}
                                />
                            }
                        <PricingPanel
                            hash={reportHash}
                            locale={locale.pricing.team}
                            action="mailto:Q24@qegroup.cz?subject=Team profile inquiry"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Pricing
