import React from "react"
import Layout from "../components/Layout"
import Pricing from "../components/Pricing";
import View from "../components/View";


const pricing = (props) => {
    return (
        <Layout>
            <View title={'Pricing'}>
                <div className={'min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative'}>
                    <div
                        className={'flex-1 mx-auto rounded-3xl bg-white shadow-xl p-10 text-gray-800 relative md:flex items-center text-center md:text-left'}
                        style={{maxWidth: '1000px'}}>
                        <div className={'w-full'}>
                            <Pricing hash={props.hash}/>
                        </div>
                    </div>
                    <div
                        className={'w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform'}/>
                    <div
                        className={'w-96 h-full bg-indigo-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform'}/>
                </div>
            </View>
        </Layout>
    )
}

export default pricing
