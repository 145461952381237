import React from "react"
import {Link} from "gatsby";


class PricingPanel extends React.Component {
    render() {
        const {locale, type, hash, action} = this.props

        return (
            <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                <div className="p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900">{locale.title}</h2>
                    <p className="mt-8 text-right">
                        <span className="text-2xl font-extrabold text-gray-800">{locale.price}</span>
                    </p>
                    <a href={action}
                        className="mt-8 w-full bg-blue-700 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-hex-067ba9 "
                        style={{display: 'flex', justifyContent: 'center'}}>
                        <span style={{display: 'flex', marginRight: '-8px'}}>
                        {type === undefined &&
                            <>Get in touch</>
                        }
                        {type !== undefined &&
                            <>Buy now</>
                        }
                        <svg style={{height: '1.25rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             className="fill-current"><path
                            className="heroicon-ui"
                            d="M9.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"/></svg>
                        </span>
                    </a>
                </div>
                <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-semibold text-hex-32454E tracking-wide uppercase">
                        What's included
                    </h3>
                    <ul className="mt-6 space-y-4">
                        {locale.features.map((feature, index) => (
                            <li className="flex space-x-3" key={index}>
                                <svg className="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <span className="text-xs leading-normal text-hex-32454E">
                                    {feature}
                                </span>
                            </li>
                        ))}
                        {type !== undefined &&
                            <li className="flex space-x-3 text-sm">
                                <Link className="text-blue-700 font-semibold" to={`/preview/${hash}/${type}`}>
                                    Take a look inside
                                    <svg style={{height: '1.25rem'}} xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 24 24"
                                         className="fill-current inline-block">
                                        <path className="heroicon-ui"
                                              d="M9.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"/>
                                    </svg>
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default PricingPanel
